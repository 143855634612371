<template>
  <div id="page">
    <!-- header and footer bar go here-->
    <div class="header header-fixed header-auto-show header-logo-app">
        <a href="#" class="header-title header-subtitle">Dr Theiss App</a>
        <a href="#" data-toggle-theme class="header-icon header-icon-3 show-on-theme-dark"><i class="fas fa-sun"></i></a>
        <a href="#" data-toggle-theme class="header-icon header-icon-3 show-on-theme-light"><i class="fas fa-moon"></i></a>
        <a href="#" data-menu="menu-main" class="header-icon header-icon-2"><i class="fas fa-bars"></i></a>
    </div>

    <footer-bar activeItem="home" />

    <div class="page-content">
        
        <div class="page-title page-title-large">
            <h2 data-username="Viliam" class="greeting-text"></h2>
            <a href="#" data-menu="menu-main" class="bg-fade-highlight-light shadow-xl preload-img" data-src="images/avatars/5s.png"></a>
        </div>
        <div class="card header-card shape-rounded" data-card-height="200">
            <div class="card-overlay bg-highlight opacity-95"></div>
            <div class="card-overlay dark-mode-tint"></div>
        </div>
        
        <div class="content" style="margin-top: 75px;">
            <div class="row mb-0">
                <router-link to="database" class="col-4">
                    <div class="card card-style text-center py-3 mx-0 mb-0">
                      <i data-feather="database" data-feather-line="1" data-feather-size="24" data-feather-color="dark-dark" data-feather-bg="gray-fade-light" class="m-auto"></i>
                      <p class="font-13 font-500 mb-n1 mt-2 color-theme">Databáza</p>
                    </div>
                </router-link>
                <router-link to="orders" class="col-4">
                    <div class="card card-style text-center py-3 mx-0 mb-0">
                      <i data-feather="file" data-feather-line="1" data-feather-size="24" data-feather-color="dark-dark" data-feather-bg="gray-fade-light" class="m-auto"></i>
                      <p class="font-13 font-500 mb-n1 mt-2 color-theme">Objednávky</p>
                    </div>
                </router-link>
                <router-link to="customers" class="col-4">
                    <div class="card card-style text-center py-3 mx-0 mb-0">
                      <i data-feather="users" data-feather-line="1" data-feather-size="24" data-feather-color="dark-dark" data-feather-bg="gray-fade-light" class="m-auto"></i>
                      <p class="font-13 font-500 mb-n1 mt-2 color-theme">Zákazníci</p>
                    </div>
                </router-link>
            </div>
        </div>

        <database-card @onStartSync="startSync()" :key="keyToUpdate" />

        <div class="card card-style">
            <div class="content">
                <h3 class="float-start">Posledné objednávky</h3>
                <router-link class="float-end font-12 color-highlight mt-n1" to="orders">Všetky</router-link>
                <div class="clearfix"></div>
                <p>
                  Zoznam vami vytvorených posledných objednávok.
                </p>
                <div class="row text-center">
                  <h5>TBD</h5>
                </div>
            </div>
        </div>
        
        <!--logout card-->
        <logout-card />

        <!-- back to top -->
        <div class="content mt-5 mb-5">
          <a href="#" class="back-to-top-badge bg-highlight shadow-xl"><i class="fa fa-caret-up"></i><span>Back to Top</span></a>
        </div>
        <!-- footer and footer card-->
        <footer-card class="footer" />
    </div>    
    <!-- end of page content-->

    <main-menu />
    
    <dr-theiss-page-modal />

    <logout-modal />

    <error-modal v-model="errorMesage" />
    <success-modal :message="successMesage" :title="successTitle" />
    <sync-modal @onFinish="onSync()" ref="syncModalRef" />
  </div>    
</template>

<script>
import { ref, inject } from 'vue'
import FooterBar from '@/components/bars/FooterBar'
import FooterCard from '@/components/cards/FooterCard'
import LogoutCard from '@/components/cards/LogoutCard'
import DatabaseCard from '@/components/cards/DatabaseCard'
import LogoutModal from '@/components/modals/LogoutModal'
import MainMenu from '@/components/menus/MainMenu'
import DrTheissPageModal from '@/components/modals/DrTheissPageModal'
import ErrorModal from '@/components/modals/ErrorModal'
import SuccessModal from '@/components/modals/SuccessModal'
import SyncModal from '@/components/modals/SyncModal'

export default {
  name: 'Home',
  components: {
    FooterBar,
    FooterCard,
    LogoutCard,
    MainMenu,
    LogoutModal,
    DrTheissPageModal,
    ErrorModal,
    SuccessModal,
    SyncModal,
    DatabaseCard
  },
  setup() {
    const errorMesage = ref('')
    const keyToUpdate = ref(0)
    const successMesage = ref('')
    const successTitle = ref('')
    const syncModalRef = ref(null)

    const appProps = inject('appProps')

    function startSync () {
      appProps.$openSyncModal()
      syncModalRef.value.startSync()
    }

    function onSync () {
      successTitle.value = 'Synchronizované'
      successMesage.value = 'Databáza bola úspešne sychronizovaná.'
      appProps.$openSuccessModal()
      keyToUpdate.value ++
    }

    return {
      errorMesage,
      successMesage,
      successTitle,
      syncModalRef,
      keyToUpdate,
      startSync,
      appProps,
      onSync
    }
  },
  mounted() {
    setTimeout(() => {
      document.init_event()
    })
  }
}
</script>

<style lang="sass">

</style>