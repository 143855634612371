import { createApp } from 'vue'
import App from './App.vue'
import router from './lib/router'
import auth from './lib/auth'
import VueCookies from 'vue3-cookies'
import htmlFrameworkInit from './lib/htmlFrameworkInit'

const app = createApp(App)
app
  .use(router)
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
  })
  .mount('#app')
const props = app.config.globalProperties
htmlFrameworkInit.init(props)
app.provide('appProps', props)

// router
router.beforeEach((to, from, next) => {
  if (to.meta.roles ?? false) {
    if (auth.hasAuthPIN(props.$cookies)) {
      props.$cookies.remove('fromName')
      next()
    } else {
      props.$cookies.set('fromName', to.name)
      next({ name: 'EnterPIN'})
    }
  } else {
    console.log('no roles')
    if (to.name === 'EnterPIN') {
      if (auth.hasAuthPIN(props.$cookies)) {
        next({ name: 'Home'})
      } else {
        next()
      }
    } else {
      props.$cookies.remove('fromName')
      next()
    }
  }
    // if (auth.hasPIN()) {
  //   if (auth.authorizedPIN(app.config.globalProperties.$cookies)) {
  //     console.log('authorized')
  //     next()
  //   } else {
  //     if (to.name === 'EnterPIN') {
  //       props.$cookies.remove('fromName')
  //       next()
  //     } else {
  //       props.$cookies.set('fromName', to.name)
  //       next({ name: 'EnterPIN' })
  //     }
  //   }
  // } else {
  //   props.$cookies.remove('fromName')
  //   if (to.name === 'Login') {
  //     console.log('login')
  //     next()
  //   } else {
  //     next({ name: 'next' })
  //   }
  // }
  // if (props.$cookies.get('token') && props.$cookies.get('expirationToken')) {
  //   if (to.name === 'Login') {
  //     next({ name: 'Dashboard' })
  //   }
  //   if (to.meta.roles) {
  //     const token = Jwt.decode(props.$cookies.get('token'))
  //     const expirationToken = Jwt.decode(props.$cookies.get('expirationToken'))
  //     if (token.id !== expirationToken.id) {
  //       props.$cookies.remove('token')
  //       props.$cookies.remove('expirationToken')
  //       props.$token = undefined
  //       props.$expirationToken = undefined
  //       next({ name: 'Login' })
  //     } else {
  //       props.$token = token
  //       props.$expirationToken = expirationToken
  //       let hasRole = false;
  //       (token.roles || []).forEach(role => {
  //         (to.meta.roles || []).forEach(requiredRole => {
  //           if (role === requiredRole)
  //             hasRole = true
  //         })
  //       })
  //       if (hasRole === true) {
  //         next()
  //       } else {
  //         next({ name: 'AccessDenhtmlFrameworkInitied' })
  //       }
  //     }
  //   } else {
  //     next()
  //   }
  // } else {
  //   if (to.name === 'Login') {
  //     props.$cookies.remove('token')
  //     props.$cookies.remove('expirationToken')
  //     props.$token = undefined
  //     props.$expirationToken = undefined
  //     next()
  //   } else {
  //     if (to.meta.roles)
  //       next({ name: 'Login' })htmlFrameworkInit
  // }
})
