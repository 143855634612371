<template>
  <div class="app-div">
    <div id="preloader"><div class="spinner-border color-highlight" role="status"></div></div>
    <div class="menu-hider"></div>
    <div class="menu-hider-dis"></div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
</style>
